
export default {
  props: {
    navData: {
      type: Array,
      default: () => [],
    },
    extraNavData: {
      type: Array,
      default: () => [],
    },
    miniVariant: {
      type: Boolean,
      default: false,
    },
    clipped: {
      type: Boolean,
      default: true,
    },
  },
  methods:{
    openUrl(navItem){
      console.log(navItem)
      if(navItem.desc==='rent'||navItem.desc==='buy'){
        this.$emit('showInquiryDialog', navItem.desc)
      }else{
        let routeData = this.$router.resolve(this.localeRoute(navItem.to))
          window.open(routeData.href, '_blank');
      }
    }
  }
}
