import { render, staticRenderFns } from "./InquiryDialog.vue?vue&type=template&id=52ca502a&scoped=true&"
import script from "./InquiryDialog.vue?vue&type=script&lang=js&"
export * from "./InquiryDialog.vue?vue&type=script&lang=js&"
import style0 from "./InquiryDialog.vue?vue&type=style&index=0&id=52ca502a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52ca502a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DashboardSharedInquriryList: require('/app/components/dashboard/shared/InquriryList.vue').default,DashboardSharedInquirySearch: require('/app/components/dashboard/shared/InquirySearch.vue').default,UiSharedDialogsDialogMFullPage: require('/app/components/ui/shared/dialogs/DialogMFullPage.vue').default})
