
import { mapState, mapMutations } from 'vuex'
export default {
  data: () => ({
    icons: ['mdi-facebook', 'mdi-twitter', 'mdi-linkedin', 'mdi-instagram'],
    nextLnk:null,
    currentRouteName:null
  }),
  computed: {
    
    nextLnk() {
      switch (this.$route.path) {
        case '/feature':
          return this.localeRoute('/home')
        case '/property-manager-about':
          return '/owner-details'
        case '/owner-details':
          return '/residents'
        case '/residents':
          return '/recommend'
        case '/recommend':
          return '/pricing'
        case '/pricing':
          return '/contact'
        default:
          return 'dont-show'
      }
    },
  },
  computed: {
    ...mapState('config/site', ['siteConfig']),
  },
  watch: {
    '$route.path': {
      handler(val) {
        console.log(this.$route)
        this.currentRouteName = val
      },
      deep: true,
    },
  },
  methods:{
  ...mapMutations('config/site', ['setLogInDialogShow', 'setScheduleShow']),
    getLink(icon){
      let link=null;
      switch(icon){
        case'mdi-facebook':
          link = this.siteConfig.facebookUrl
        break;
        case'mdi-twitter':
          link = this.siteConfig.twitterUrl
        break;
        case'mdi-linkedin':
        link = this.siteConfig.linkedInUrl
        break;
        case'mdi-instagram':
          link = this.siteConfig.instagramUrl
        break;

      }
      return link
    }
  }
}
