import { render, staticRenderFns } from "./index.vue?vue&type=template&id=21a1dd58&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=21a1dd58&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21a1dd58",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AuthRegister: require('/app/components/auth/register/index.vue').default,AuthForgetPassword: require('/app/components/auth/forget-password/index.vue').default,UiSharedErrorsErrorDialog: require('/app/components/ui/shared/errors/ErrorDialog.vue').default})
