const actions = {
  async getDashboardStatistics({ commit }, params) {
    const res = await this.$repositories.adminDashboard.getDashboardStatistics(
      params
    )

    return res.data
  },
  async getHolidayGreetingCard({ commit }, params) {
    const res = await this.$repositories.adminDashboard.getHolidayGreetingCard(
      params
    )

    return res.data
  },
  async updateHolidayCard({ commit }, params) {
    const res = await this.$repositories.adminDashboard.updateHolidayCard(
      params
    )

    return res.data
  },
  async getHolidayGreetingCardGallery({ commit }, params) {
    const res =
      await this.$repositories.adminDashboard.getHolidayGreetingCardGallery(
        params
      )

    return res.data
  },
  async deleteImageFromGallery({ commit }, params) {
    const res = await this.$repositories.adminDashboard.deleteImageFromGallery(
      params
    )

    return res.data
  },
  async updateHolidayGreetingCardGalleryText({ commit }, params) {
    const res =
      await this.$repositories.adminDashboard.updateHolidayGreetingCardGalleryText(
        params
      )

    return res.data
  },
  async getProperdeeAlerts({ commit }, params) {
    const res = await this.$repositories.adminDashboard.getProperdeeAlerts(
      params
    )

    return res.data
  },
  async addProperdeeAlerts({ commit }, params) {
    const res = await this.$repositories.adminDashboard.addProperdeeAlerts(
      params
    )

    return res.data
  },
  async updateProperdeeAlerts({ commit }, params) {
    const res = await this.$repositories.adminDashboard.updateProperdeeAlerts(
      params
    )

    return res.data
  },
  async deleteProperdeeAlerts({ commit }, params) {
    const res = await this.$repositories.adminDashboard.deleteProperdeeAlerts(
      params
    )

    return res.data
  },
  async getAllUsers({ commit }, params) {
    const res = await this.$repositories.adminDashboard.getAllUsers(params)

    return res.data
  },
  async getAllUsersDetails({ commit }, params) {
    const res = await this.$repositories.adminDashboard.getAllUsersDetails(
      params
    )

    return res.data
  },
  async addVideoToLib({ commit }, params) {
    const res = await this.$repositories.adminDashboard.addVideoToLib(params)

    return res.data
  },
  async updateVideoToLib({ commit }, params) {
    const res = await this.$repositories.adminDashboard.updateVideoToLib(params)

    return res.data
  },
  async deleteVideoToLib({ commit }, params) {
    const res = await this.$repositories.adminDashboard.deleteVideoToLib(params)

    return res.data
  },
  async getVideoToLib({ commit }, params) {
    const res = await this.$repositories.adminDashboard.getVideoToLib(params)

    return res.data
  },
  async deleteUser({ commit }, params) {
    const res = await this.$repositories.adminDashboard.deleteUser(params)

    return res.data
  },
  async getAllSubscriber({ commit }, params) {
    const res = await this.$repositories.adminDashboard.getAllSubscriber(params)

    return res.data
  },
  async verifySubscriber({ commit }, params) {
    const res = await this.$repositories.adminDashboard.verifySubscriber(params)

    return res.data
  },
  async usertoAdminChange({ commit }, params) {
    const res = await this.$repositories.adminDashboard.usertoAdminChange(params)

    return res.data
  },
  async addEnquiry({ commit }, params) {
    const res = await this.$repositories.adminDashboard.addEnquiry(params)

    return res.data
  },
  async getAllInquiry({ commit }, params) {
    const res = await this.$repositories.adminDashboard.getAllInquiry(params)

    return res.data
  },
  async deleteEnquiry({ commit }, params) {
    const res = await this.$repositories.adminDashboard.deleteEnquiry(params)

    return res.data
  },
}

export default actions
