const controller = '/user'
export default axios => ({
  setUserRole(obj) {
    return axios.put(`${controller}/update-role`, obj)
  },

  updateBusinessName(obj) {
    return axios.post(`${controller}/business-name`, obj)
  },
  
  createSubDomain(obj) {
    return axios.post(`${controller}/create-subdomain`, obj)
  },
  checkSubDomain(obj) {
    return axios.post(`${controller}/check-subdomain`, obj)
  },
  createCompany(obj) {
    return axios.post(`${controller}/create-company`, obj)
  },
  shareProperdee(obj) {
    return axios.post(`${controller}/share-properdee`, obj)
  },
  getListOfAlerts(obj) {
    let parmas = `?itemsPerPage=${obj.itemsPerPage}&page=${obj.page}&dashboard=${obj.isDashboard}`

    return axios.get(`${controller}/get-properdee-alerts${parmas}`)
  },
  getAlertDetail(obj) {
    return axios.get(`${controller}/get-properdee-alerts/${obj.alertId}`)
  },
  getMyAccount(obj) {
    return axios.get(`${controller}/get-my-account-details`)
  },
  updateMyAccount(obj) {
    return axios.put(`${controller}/update-my-account-details`, obj)
  },
  doneProperdeeAlerts(obj) {
    return axios.get(`${controller}/done-properdee-alerts/${obj.id}`)
  },
  getRecentTasks(obj) {
    return axios.get(`${controller}/get-recent-task`)
  },
  getTasks(obj) {
    return axios.get(`${controller}/get-date-and-task`)
  },
  getTaskCallender(obj) {
    let parmas = `?date=${obj.date}&itemsPerPage=${obj.itemsPerPage}&page=${obj.page}`
    return axios.get(`${controller}/get-calendar${parmas}`)
  },
  addTaskCallender(obj) {
    return axios.post(`${controller}/add-calendar`, obj)
  },
  deleteTaskCallender(obj) {
    return axios.delete(`${controller}/delete-calendar/${obj.id}`, obj)
  },
  resetUser() {
    return axios.put(`${controller}/reset-user/`)
  },
  getContactDetails() {
    return axios.get(`${controller}/contact-details/`)
  },
  updateContactDetails(obj) {
    console.log("obj",obj)
    return axios.put(`${controller}/contact-details`,obj.obj)
  },
  
})
